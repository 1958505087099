.custom-navbar {
  background-color: #ffffff; 
  font-family: Arial, sans-serif;
}
.responsiveheader{
  border-bottom: 2px solid white;
}
.custom-navbar .navbar-brand {
  display: flex;
  align-items: center;
  font-size: 1.25rem; 
  color: #005030; 
  font-weight: bold; 
  margin-right: 2rem; 
}
.custom-navbar .navbar-nav .nav-item.active .nav-link {
  color: #002010; 
  background-color: #47a447; 
}
.custom-navbar .navbar-nav .nav-link {
  font-size: 1rem;
  color: #004020; 
  font-weight: bold;
  text-transform: uppercase; 
  margin-right: 1rem;
}

.custom-navbar .navbar-nav .nav-link:hover {
  color: #002010; 
}

.custom-navbar .navbar-toggle {
  border: none;
}

.custom-navbar .donate-button {
  background-color: #47a447; 
  border: none;
  border-radius: 20px; 
  padding: 0.5rem 1rem;
  color: white; 
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
}

.custom-navbar .donate-button:hover {
  background-color: #398439; 
}

/* .logo {
  height: 2rem;
  margin-right: 0.5rem;
} */

.scrolled {
  background-color: white;
  transition: background-color 0.5s ease; 
}
@media (max-width: 768px) {
  .feat-wrap.v2  {
    max-width: 100%; 
    max-height: 200px;
    margin: 0 auto; /* Center the container */
    position: relative; /* Add position relative */
  }
  .feat-img {
    max-width: 100%; 
    max-height: 200px;
    margin: 0 auto;
  }

  /* Adjust the position of slider dots */
  .slick-dots {
    position: absolute;
    bottom: 10px; /* Adjust the distance from the bottom as needed */
    left: 0; /* Position dots relative to the left side of the container */
    right: 0; /* Position dots relative to the right side of the container */
    margin: 0 auto; /* Center dots horizontally within the container */
    width: auto; /* Allow dots to expand to fit content */
    text-align: center; /* Center dots horizontally within the container */
    z-index: 1; /* Ensure dots appear above other elements */
  }
}

