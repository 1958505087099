/* HomeAudio.css */

.home-audio-section {
    position: relative;
    padding-top: 190px;
    padding-bottom: 120px;
  }
  
  .audio-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.9;
  }
  
  .audio-background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .container {
    position: relative;
    z-index: 1;
  }
  
  .audio-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .audio-player {
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
  
  .audio-title {
    margin-bottom: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    color: #fff;
  }
  
  .audio-player-inner {
    background-color: rgba(0, 0, 0, 0.65);
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
  }
  
  .react-audio-player {
    width: 100%;
  }
  
  .audio-content {
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
    margin-top: 15px;
  }
  
  .content-category {
    display: block;
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .content-title {
    margin: 0 0 20px;
    font-size: 28px;
    color: #fff;
  }
  
  .content-description {
    color: #fff;
    margin-bottom: 15px;
  }
  
  .thm-btn {
    display: inline-block;
    padding: 12px 20px;
    font-size: 16px;
    background-color: #fff;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .thm-btn:hover {
    background-color: #333;
    color: #fff;
  }
  @media (max-width: 768px) {
    .audio-container {
      flex-direction: column;
    }
  
    .audio-player,
    .audio-content {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  @media (max-width: 576px) {
    .audio-title {
      font-size: 20px;
    }
  
    .content-category {
      font-size: 14px;
    }
  
    .content-title {
      font-size: 24px;
    }
  
    .content-description {
      font-size: 14px;
    }
  
    .thm-btn {
      font-size: 14px;
    }
  }  