/* Base container styles for overall alignment and padding */
.prayer-time-container {
  background: #ffffff; /* Lighter background for better contrast */
  color: #333; /* Darker text for better readability */
  padding: 10px;
  text-align: center;
}

/* Header styling for the title */
.prayer-time-header span {
  font-size: 1em; /* Slightly larger for readability */
  color: #555; /* Softer color for less contrast */
}

.prayer-time-header h1 {
  margin: 20px 0; /* More space around the header */
  font-size: 2.5em; /* Larger size for prominence */
  font-weight: 300; /* Lighter weight for a modern look */
  color: #5a432a; /* Darker color for contrast */
}

/* Grid container for cards with responsive spacing */
.prayer-time-grid {
  display: flex;
  justify-content: space-around; /* Even spacing on all sides */
  flex-wrap: wrap;
  gap: 15px; /* Reduced gap for a tighter layout */
}

/* Card styles for a more polished look */
.prayer-time-card {
  background: #c9a56a; /* Warmer shade for the card background */
  padding: 25px; /* More padding for a spacious look */
  border-radius: 10px; /* Rounded corners for a softer look */
  width: calc(100% / 3 - 30px); /* Three cards per row with spacing */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease; /* Smooth transition for hover effect */
}

.prayer-time-card:hover {
  transform: translateY(-5px); /* Slight raise effect on hover */
}

/* Name of the prayer for clearer hierarchy */
.prayer-name {
  font-size: 1.3em; /* Larger font size for importance */
  margin-bottom: 15px; /* More space before the time */
  color: #5a432a; /* Darker color for readability */
}

/* Time box styling for a standout look */
.prayer-time {
  background: #a67c52; /* A different shade for contrast */
  color: #fff; /* White text for legibility */
  padding: 15px; /* Generous padding for touch targets */
  border-radius: 10px; /* Consistent rounded corners */
  font-weight: bold; /* Bold font for emphasis */
  font-size: 20px;
}


@media (max-width: 768px) {
  .prayer-time-grid {
    gap: 5px; 
  }

  .prayer-time-card {
    width: 40%; 
    margin-bottom: 10px; 
  }
}
