@media (max-width: 600px) {
    .pagination .page-item {
      margin: 2px;
    }
  
    .pagination .page-link {
      font-size: 14px;
      padding: 4px 6px;
    }
  }
  
  @media (min-width: 601px) and (max-width: 1024px) {
    .pagination .page-item {
      margin: 4px;
    }
  
    .pagination .page-link {
      font-size: 14px;
      padding: 6px 8px;
    }
  }
  
  @media (min-width: 1025px) {
    .pagination .page-item {
      margin: 5px;
    }
  
    .pagination .page-link {
      font-size: 16px;
      padding: 8px 10px;
    }
  }
  
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    flex-direction: column;
    color: white;
  }
  
  .spinner {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #1e20a3;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  